<template>
    <div
        class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 relative"
        id="login"
    >
        <div
            class="mt-8 w-full p-2 sm:max-w-md absolute right-0 sm:pr-0 lg:pr-10"
        >
            <div
                class="bg-white py-8 px-4 rounded-md sm:px-10 shadow"
                style="--bg-opacity: 0.9"
            >
                <div class="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        class="mx-auto h-12 w-auto"
                        src="@/assets/imgs/naef-logo.png"
                        alt="logo Naef"
                    />
                    <h2
                        v-if="!connected"
                        class="mt-6 text-center text-2xl leading-9 font-bold text-blue-900"
                    >
                        Bienvenue
                    </h2>
                </div>
                <!-- if not logged in -->
                <div v-if="!connected">
                    <form action="#" method="POST" @submit.prevent="handleLoginClick">
                        <div>
                            <label
                                for="email"
                                class="block text-sm font-medium leading-5 text-gray-700"
                                >Email</label
                            >
                            <div class="mt-1 rounded-md shadow-sm">
                                <input
                                    v-model="email"
                                    dusk="email"
                                    id="email"
                                    :type="emailFieldType"
                                    required
                                    class="appearance-none block w-full py-2 border-b border-red-600 bg-transparent placeholder-gray-400 focus:outline-none focus:border-blue-400 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                />
                            </div>
                        </div>

                        <div class="mt-6">
                            <label
                                for="password"
                                class="block text-sm font-medium leading-5 text-gray-700"
                                >Mot de passe</label
                            >
                            <div class="mt-1 rounded-md shadow-sm">
                                <input
                                    v-model="password"
                                    dusk="password"
                                    id="password"
                                    type="password"
                                    required
                                    class="appearance-none block w-full py-2 border-b border-red-600 bg-transparent placeholder-gray-400 focus:outline-none focus:border-blue-400 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                />
                            </div>
                        </div>

                        <div class="mt-6 flex items-center justify-between">
                            <div class="flex items-center" v-show="false">
                                <input
                                    id="remember_me"
                                    type="checkbox"
                                    class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                />
                                <label
                                    for="remember_me"
                                    class="ml-2 block text-sm leading-5 text-blue-900"
                                    >Rester connecté</label
                                >
                            </div>

                            <div class="text-sm leading-5">
                                <router-link
                                    :to="{ name: 'password-reset' }"
                                    class="font-medium text-red-600 hover:text-red-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                                    >Mot de passe oublié ?</router-link
                                >
                            </div>
                        </div>

                        <div class="mt-6">
                            <span class="block w-full rounded-sm shadow-sm">
                                <button
                                    type="submit"
                                    dusk="submit"
                                    class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:outline-none transition duration-150 ease-in-out"
                                    :class="{
                                    'cursor-not-allowed bg-gray-500' : loading,
                                    'bg-red-700 hover:bg-red-600' : !loading
                                    }"
                                >
                                    <span v-if="loading" class="tracking-wide font-bold">
                                        ...
                                    </span>
                                    <span v-else>
                                        Se connecter
                                    </span>
                                </button>
                            </span>
                        </div>
                    </form>
                </div>

                <div class="mt-6 text-center font-medium text-blue-900" v-else>
                    <p>Merci de vous déconnecter si vous souhaitez changer de compte</p>
                    <button 
                        class="w-full mt-2 flex bg-red-700 justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:outline-none transition duration-150 ease-in-out"
                        @click="logout"
                    >
                        Se déconnecter
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Cookies from "js-cookie";
import Redirect from "@/mixins/Redirect";
import Auth from "@/mixins/Auth";


export default {
    mixins: [Redirect, Auth],
    data() {
        return {
            email: "",
            password: "",
            loading : false,
        };
    },
    computed : {
        demoDemo(){
            return this.email == "DEMO" && this.password == "DEMO";
        },
        emailFieldType(){
            return this.email == "DEMO" ? "text" : "email";
        }
    },
    mounted(){
        // Pour se connecter en DEMO, on passe forcément par un URL Query param
        const demoUserType = this.$route.query.demo;
        if(demoUserType){
            const body = {
                email : `${demoUserType}.demo@naef.ch`,
                password : "NaefNaefDemo!"
            };
            localStorage.setItem("demo", demoUserType);
            this.login(body);
        }
    },
    methods: {
        startLoading(){
            this.loading = true;
        },
        stopLoading(){
            this.loading = false;
        },
        handleLoginClick() {
            this.startLoading();

            if(this.demoDemo){
                this.$router.push({name : "demo"});
                return;
            }
            localStorage.removeItem("demo");

            const body = {
                email: this.email,
                password: this.password,
            };

            this.login(body);
        },
        login(body){
            Cookies.remove("token");
            return this.$store.dispatch("auth/login", body).then((loginResponse) => {
                if (!loginResponse.status || loginResponse.status === 404) {
                    this.$toast.open({
                        type: "error",
                        message: "E-mail ou mot de passe incorrect",
                    });
                    this.stopLoading();
                    return;
                }

                this.$store
                    .dispatch("auth/user")
                    .then((res) => {
                        if(res.status == 200){
                            
                            let token = this.$store.getters["auth/token"];
                            Cookies.set("token", token, { expires: 30 });
                            
                            let redirectLink = localStorage.getItem("redirectAfterLogin");
    
                            this.$store.dispatch("app/filters").then(() => {
                                if(redirectLink){
                                    this.$toast.open({
                                        type: "info",
                                        message: "Redirection...",
                                    });
                                    setTimeout(() => {
                                        localStorage.removeItem("redirectAfterLogin");
                                        window.location.href = redirectLink;
                                    }, 2000);
                                }
                                else{
                                    this.redirectHome();
                                }
                            });
                        }
                        this.stopLoading();
                    })
                    .catch((err) => {
                        this.stopLoading();
                        this.$toast.open({
                            type: "error",
                            message: "Une erreur est survenue",
                        });
                        throw err;
                    });
            });
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Login";
            }
        },
    },       
};
</script>

<style scoped>
#login {
    background-image: url("/img/nouvelle-identite/login-1.jpg") !important;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
</style>
